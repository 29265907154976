/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import '@/assets/styles/app.scss';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import routes from '@/router/routes';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useMediaQuery} from 'react-responsive';
import Logo from '@/assets/images/qredit-logo.png';
import '@/context/i18n';
import {stateType} from '@/types/state';
import {useSelector} from 'react-redux';
import FooterImage from '@/assets/images/Qredits_footer_rgb.png';
import useLocales from '@/hooks/useLocales';
import {
  findLangByEnv,
  findMyQreditsLink,
  linkByEnv,
} from '@/components/utils/helper';

const App: React.FC = () => {
  const state = useSelector((state: stateType) => state);
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 912px)'});
  const {translate, onChangeLang} = useLocales();

  useEffect(() => {
    onChangeLang(findLangByEnv());
  }, []);

  React.useEffect(() => {
    if (!state.finalStep)
      window.addEventListener('beforeunload', (event) => {
        event.preventDefault();
        event.returnValue = !state.finalStep ?
          `Are you sure that you want
    to close the application ?` :
          '';
      });

    return () => {
      window.removeEventListener('beforeunload', (event) => {
        event.preventDefault();
        event.returnValue = !state.finalStep ?
          `Are you sure that you want
    to close the application ?` :
          '';
      });
    };
  }, [state.finalStep]);

  return (
    <div
      style={{
        overflowY: 'auto',
        height: '100vh',
      }}
    >
      <Navbar bg="white" expand="lg" sticky="top">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <Nav.Link
              href={linkByEnv()}
              className="text-primary"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                width="32"
                height="19"
                viewBox="0 0 12 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.36384 3.75296L9.68747 3.40963L9.36347
                 3.06664L8.03148 1.65665L7.66802 1.27189L7.30455
                  1.65665L1.63653 7.65664L1.31218 8L1.63653 8.34336L7.30455
                   14.3434L7.66802 14.7281L8.03148 14.3434L9.36347
                    12.9334L9.68747 12.5904L9.36384 12.247L5.36053 8L9.36384
                     3.75296Z"
                  fill="#002172"
                  stroke="#002172"
                />
              </svg>
            </Nav.Link>
          </Navbar.Toggle>
          <Navbar.Brand href={linkByEnv()} target="_blank" rel="noreferrer">
            {!isTabletOrMobile ? (
              <img
                className="desktop-brand"
                src={Logo}
                alt=""
                height={125}
                width={125}
              />
            ) : (
              <img
                className="mobile-brand"
                src={Logo}
                alt=""
                height={66}
                width={66}
              />
            )}
          </Navbar.Brand>
          {!isTabletOrMobile && (
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={
                !isTabletOrMobile ? 'text-primary' : 'text-primary mob-drop'
              }
            >
              <Nav.Link
                href={linkByEnv()}
                className="text-primary"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="32"
                  height="19"
                  viewBox="0 0 12 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.36384 3.75296L9.68747 3.40963L9.36347
                 3.06664L8.03148 1.65665L7.66802 1.27189L7.30455
                  1.65665L1.63653 7.65664L1.31218 8L1.63653 8.34336L7.30455
                   14.3434L7.66802 14.7281L8.03148 14.3434L9.36347
                    12.9334L9.68747 12.5904L9.36384 12.247L5.36053 8L9.36384
                     3.75296Z"
                    fill="#002172"
                    stroke="#002172"
                  />
                </svg>
                {translate('go_back_home')}
              </Nav.Link>
            </Navbar.Collapse>
          )}
          <Nav.Link
            href={findMyQreditsLink()}
            className={!isTabletOrMobile ? 'text-warning' : 'text-primary'}
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="35"
              height="20"
              viewBox="0 0 15 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.75 10.3732C11.4844 10.3732 13.75 8.14661 13.75
               5.37317C13.75 2.63879 11.4844 0.373169 8.75 0.373169C5.97656
                0.373169 3.75 2.63879 3.75 5.37317C3.75 8.14661 5.97656
                 10.3732 8.75 10.3732ZM10.7031 12.2482H6.75781C3.00781
                  12.2482 0 15.295 0 19.045C0 19.7872 0.585938 20.3732
                   1.32812 20.3732H16.1328C16.875 20.3732 17.5 19.7872
                    17.5 19.045C17.5 15.295 14.4531 12.2482 10.7031
                     12.2482Z"
                fill={!isTabletOrMobile ? '#FF6600' : '#002172'}
              />
            </svg>
            {isTabletOrMobile ? ' ' : translate('my_qredits')}
          </Nav.Link>
        </Container>
      </Navbar>
      <div className='content'>
        <Router>
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
        </Router>
      </div>
      <div
        className="custom-footer"
      >
        <img className="footer-image" src={FooterImage} alt="" width={'100%'} />
        <div className="d-flex align-items-center footer-content">
          <h5>{translate('business_start_with')}</h5>
          <div>
            {!isTabletOrMobile ? (
              <img
                className="desktop-brand"
                src={Logo}
                alt=""
                height={80}
                width={80}
              />
            ) : (
              <img
                className="mobile-brand"
                src={Logo}
                alt=""
                height={50}
                width={50}
              />
            )}
          </div>
        </div>
      </div>
      <div className='bottom-blocker'></div>
    </div>
  );
};

export default App;
