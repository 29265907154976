/* eslint-disable @typescript-eslint/no-explicit-any */
import {apiHttp} from '@/apis/axiosHttp';

const readList = (endpoint: string) => {
  return apiHttp.get<Array<any>>(endpoint);
};

const readById = (endpoint: string, id: any) => {
  return apiHttp.get<any>(`${endpoint}/${id}`);
};

const create = (endpoint: string, data: any) => {
  return apiHttp.post<any>(endpoint, data);
};

const postOnly = (endpoint: string) => {
  return apiHttp.post<any>(`${endpoint}/`);
};

const update = (endpoint: string, id: any, data: any) => {
  return apiHttp.put<any>(`${endpoint}/${id}`, data);
};

const removeById = (endpoint: string, id: any) => {
  return apiHttp.delete<any>(`${endpoint}/${id}`);
};

const removeAll = (endpoint: string) => {
  return apiHttp.delete<any>(endpoint);
};

const findWithParams = (endpointWithParams: string) => {
  return apiHttp.get<any>(endpointWithParams);
};

const axiosApi = {
  readList,
  readById,
  create,
  update,
  removeById,
  postOnly,
  removeAll,
  findWithParams,
};
export default axiosApi;
