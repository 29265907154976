/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {useSelector} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import {stateType, MetaDataProps} from '@/types/state';
import InputField from '@/components/common/InputField';
import store, {setLeadData, setSelectedProduct} from '@/store';
import useLocales from '@/hooks/useLocales';
// import apiHttp from '@/apis/axiosApi';
import {authHttp} from '@/apis/axiosHttp';
import toast from 'react-hot-toast';
import TagManager from 'react-gtm-module';
import env from '@/constants/env';

type Props = {
  handleDone: ({loan, year, key}) => void;
  getData: ({loan, year, interestRate}) => void;
};
const durationMap: Record<string, number> = {
  NL: 5,
  SXM: 6,
  BES: 5,
  default: 6, // Default value if no match is found
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const CreditForm = ({handleDone, getData}: Props) => {
  const inputLoanRef = React.useRef(null);
  const inputYearRef = React.useRef(null);
  const state = useSelector((state: stateType) => state);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [year, setYear] = React.useState<number>(
    state.lead.Duration__c ?
      Number(state.lead.Duration__c) : durationMap[env.Country] ||
      durationMap.default);
  const [newYear, setNewYear] = React.useState<number>(50);
  const [loan, setLoan] = React.useState<number>(
    state.lead.RequestedCreditAmount__c ?
    Number(state.lead.RequestedCreditAmount__c ): env.Country !== 'NL' ?
  25000 : 20000);
  const [maxYearValue, setMaxYearValue] = React.useState(0);
  // const [minYearValue, setMinYearValue] = React.useState(0);
  let [valueLoan, setValue] = React.useState<number>(50);
  const progressYear = (newYear / 100) * 100 + '%';
  const active = '#5EC369';
  const inactive = '#002172';
  const [interestRate, setInterestRate] = React.useState<number>(8.75);
  const [product, setProduct] = React.useState<string>(
    state.lead.Proposition__c ?
   state.lead.Proposition__c : '');
  const [coCNumber, setCoCNumber] = React.useState<string>(
    state.lead.COC_Number__c ?
    state.lead.COC_Number__c : '');
  const [products, setProducts] = React.useState<MetaDataProps[]>([]);
  const styleLoanInput = {
    background: `linear-gradient(90deg, ${active} 0% ${valueLoan}%,
        ${inactive} ${valueLoan}% 100%)`,
  };
  const styleYearInput = {
    background: `linear-gradient(90deg, ${active} 0% ${progressYear},
       ${inactive} ${progressYear} 100%)`,
  };
  const {translate} = useLocales();

  const firstQuadraticValue = env.Country !== 'NL' ? 44555.6 : 46777.8;
  const secondQuadraticValue = env.Country !== 'NL' ? 50500 : 57166.7;
  const thirdQuadraticFactor = env.Country !== 'NL' ? 500 :
    390;

  const logslider = (position) => {
    // position will be between 0 and 100
    const minp = 0;
    const maxp = 100;

    // The result should be between 1 and 20
    const minv = Math.log(1);
    const maxv = Math.log(20);

    // calculate adjustment factor
    const scale = (maxv - minv) / (maxp - minp);

    return Math.round(Math.exp(minv + scale * (position - minp)));
  };

  const logposition = (value) => {
    // value will be between 1 and 20
    const minv = Math.log(1);
    const maxv = Math.log(20);

    // The result should be between 0 and 100
    const minp = 0;
    const maxp = 100;

    // calculate adjustment factor
    const scale = (maxv+1 - minv) / (maxp - minp);

    return Math.round((Math.log(value) - minv) / scale + minp);
  };

  const handleNewYearChange = (event) => {
    const inputValue = parseInt(event.target.value);
    // Calculate the max duration based on the logarithmic scale
    setYear(logslider(inputValue));
    setNewYear(inputValue);
    const progress = (inputValue / 100) * 100 + '%';
    const payload = {
      Duration__c: logslider(inputValue),
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0%
       ${progress}%,
       ${inactive} ${progress}% 100%)`;
    if (inputYearRef.current?.style)
      inputYearRef.current.style.background = newBackgroundStyle;
  };

  const transform = (value) => {
    let newValue = 0;
    if (value < 50) {
      newValue = thirdQuadraticFactor * value + 500;
    } else {
      value = value / 33.33333333333333333;
      newValue = Math.floor(
        firstQuadraticValue * Math.pow(value, 2) - secondQuadraticValue *
        value + 500,
      );
    }
    return Math.floor(newValue / 100) * 100;
  };

  const solveQuadratic = (y) => {
    const b = -secondQuadraticValue;
    const c = 500 - y;
    const discriminant = b ** 2 - 4 * firstQuadraticValue * c;

    if (discriminant < 0) {
      // The quadratic equation has no real solutions for the given value of y
      return NaN;
    }

    const x1 = (-b + Math.sqrt(discriminant)) / (2 * firstQuadraticValue);
    const x2 = (-b - Math.sqrt(discriminant)) / (2 * firstQuadraticValue);

    // Return the bigger of the two solutions
    return Math.max(x1, x2);
  };

  const transformBack = (value) => {
    let newValue = 0;
    const theMedian = env.Country !== 'NL' ? 25000 : 20000;
    if (value <= theMedian) {
      newValue = (value - 500) / thirdQuadraticFactor;
    } else {
      newValue = solveQuadratic(value);
      newValue = newValue * (100 / 3);
    }
    return Math.round(newValue);
  };

  const handleLoanChange = () => () => {
    const inputs = JSON.parse(
      (document.getElementById('sliderId') as HTMLInputElement).value,
    );
    valueLoan = transform(inputs);
    setLoan(valueLoan);
    setValue(inputs);
    const progress = (inputs / 100) * 100 + '%';
    const payload = {
      RequestedCreditAmount__c: valueLoan,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0%
      ${progress}%,
      ${inactive} ${progress}% 100%)`;
   inputLoanRef.current.style.background = newBackgroundStyle;
  };

  const convertEuro = (numb: number) => {
    let value = null;
    switch (env.Country) {
      case 'NL':
        value = new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
        }).format(Number(numb));
      break;
      case 'ABW':
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'AWG',
        }).format(Number(numb));
      break;
      case 'CUW':
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'ANG',
        }).format(Number(numb));
      break;
      default:
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(Number(numb));
        break;
    }
    return value;
  };

  const handleLoanText = (event) => {
    const inputAmountValue = document.getElementById(
      'floatingInput',
    ) as HTMLInputElement;
    let valueText = 0;
    const eventReplaceDot = env.Country === 'NL' ? event
      .replace(/\./g, '')
      .replace(',', '.')
      // eslint-disable-next-line
      .replace(/[^0-9\.-]+/g, "") : event.replace(/[^0-9\.-]+/g, "");
    valueText =
      parseFloat(eventReplaceDot) >= 250000 ?
        250000 :
        parseFloat(eventReplaceDot);
    valueLoan = transformBack(valueText);
    setTimeout(() => (inputAmountValue.value = convertEuro(valueText)), 100);
    setLoan(valueText);
    setValue(valueLoan);
    const payload = {
      RequestedCreditAmount__c: valueText,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
  };
  const tagManagerArgs = {
    gtmId: env.GtmId,
    dataLayer: {
      'event': 'Krediet aanvraag stap',
      'stap_aanvraag': 'Kredietinformatie',
      'type_aanvraag': product,
      'leenbedrag': loan,
      'looptijd': year,
    },
  };
  const addDataLayer = () => {
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem('loanInfo', JSON.stringify(tagManagerArgs.dataLayer));
    // console.log('credit', window.dataLayer);
  };

  const handleYearChange = (event) => {
    const value = Number(event.target.value);
    const progress = (logposition(value) / 100) * 100 + '%';
    setYear(value);
    setNewYear(logposition(value));
    const payload = {
      Duration__c: value,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0%
       ${progress}%,
       ${inactive} ${progress}% 100%)`;
    if (inputYearRef.current?.style)
      inputYearRef.current.style.background = newBackgroundStyle;
  };

  React.useEffect(() => {
    getData({year, loan, interestRate});
    if (year > 0 && loan > 0 && product) setValidated(true);
    else setValidated(false);
  }, [year, loan, getData, interestRate, product]);

  React.useEffect(() => {
    // const searchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(Array.from(searchParams.entries()));
    if (!product) {
      const prod = state.metaData.filter((el) => !!el.InterestPercentage__c);
      // Find the index of the element with the specific id
      const indexToMove = prod.findIndex(
        (obj) => obj.Label === 'Zakelijk Krediet',
      );

      // If the element is found, move it to the beginning of the array
      if (indexToMove !== -1) {
        const elementToMove = prod.splice(indexToMove, 1)[0];
        prod.unshift(elementToMove);
      }
      setProducts(prod);
      const chosen = state.lead.Proposition__c || product;
      const prodfinal = prod.filter((el) => el.Label ===
      chosen);
      setProduct(prodfinal[0]?.Label || prod[0]?.Label);
      setInterestRate(prodfinal[0]?.InterestPercentage__c ||
        prod[0]?.InterestPercentage__c);
      store.dispatch(setSelectedProduct(prodfinal[0] || prod[0]));
      setLoan(state.lead.RequestedCreditAmount__c);
      setYear(state.lead.Duration__c);
    }
  }, [product, state]);

  React.useEffect(() => {
    const prod = products.filter((el) => el.Label === product);
    setProduct(prod[0]?.Label);
    setInterestRate(prod[0]?.InterestPercentage__c || 0);
    store.dispatch(setSelectedProduct(prod[0]));
  }, [product, products]);

  React.useEffect(() => {
    if (coCNumber) {
      const payload = {
        COC_Number__c: coCNumber,
      };
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    }
  }, [coCNumber]);

  React.useEffect(() => {
    handleLoanText(`${loan}`);
    handleYearChange({
      target: {
        value: year,
      },
    });
    setMaxYearValue(20);
    // setMinYearValue(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // for (let i = 120; i >= 1; i -= 12) {
  //   options.push(<option value={i}>{i} maanden</option>);
  // }
  const updateLead = () => {
    const toastId = toast.loading(translate('loading'));
    const payload = {
      RequestedCreditAmount__c: loan,
      Duration__c: Number(year) * 12,
      Proposition__c: product,
    };
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      ...payload,
    })
    // apiHttp
    //   .create('api/salesforce/', {
    //     method: 'PATCH',
    //     path: `services/data/v57.0/sobjects/Lead/${store.getState().id}`,
    //     headers: [
    //       {
    //         key: 'Content-Type',
    //         value: 'application/json',
    //       },
    //     ],
    //     body: payload,
    //   })
      .then(() => {
        store.dispatch(setLeadData({...store.getState().lead, ...payload}));
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
      });
  };

  const handleSubmit = () => {
    addDataLayer(),
    handleDone({
      loan,
      year,
      key: 1,
    });
    if (coCNumber) {
      const payload = {
        COC_Number__c: coCNumber,
      };
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    }
    if (store.getState().id) updateLead();
  };

  return (
    <Card.Body className="desktop_form">
      <p className="mt-4 mobile_details">
        {translate('fill_as_many_fields')}
      </p>
      <div className="mb-4 align-items-center">
        <Form.Group controlId="validationCustom01">
          <Row className="align-items-center mb-2">
            <Col xs={6} md={6} className="row mt-3 align-items-end">
              <Form.Label className="mb-1">{translate('loan_amt')}</Form.Label>
            </Col>
            <Col xs={6} md={6} className="row m-0 justify-content-end p-0">
              <InputField
                styles={{textAlign: 'right', padding: '0px'}}
                handleFocus={(e: string) => handleLoanText(e)}
                type="all"
                defaultValue={convertEuro(loan)}
              />
            </Col>
          </Row>
          <input
            ref={inputLoanRef}
            id="sliderId"
            className="inputR"
            name="sliderName"
            type="range"
            min={0}
            max={100}
            value={valueLoan}
            onChange={handleLoanChange()}
            style={styleLoanInput}
          />
        </Form.Group>
      </div>
      <div className="mb-5">
        <Form.Group controlId="validationCustom01">
          <Row className="align-items-center mb-4">
            <Col xs={6} md={6} className="row mt-3 align-items-end">
              <Form.Label>{translate('duration')}</Form.Label>
            </Col>
            <Col xs={6} md={6} className="row m-0 justify-content-end p-0">
              <Form.Select
                className="short_select"
                aria-label="Select year"
                value={year}
                onChange={(e) => handleYearChange(e)}
              >
                {Array(maxYearValue)
                  .fill(0)
                  .map((_, index) => (
                    <option value={index + 1} key={index}>
                      {' '}
                      {index + 1} {translate('year')}
                    </option>
                  ))}
                {/* {
                  options
                } */}
              </Form.Select>
            </Col>
          </Row>
          <input
            type="range"
            id="sliderIds"
            className="inputR mt-6"
            name="sliderName"
            min="1"
            max="100"
            value={newYear}
            onChange={handleNewYearChange}
            step="1"
            style={styleYearInput}
          />
        </Form.Group>
      </div>
      <Form.Select
        className="full_select"
        aria-label="Select type"
        value={product}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setProduct(e.target.value)
        }
      >
        {products.map((product, index) => (
          <option value={product.Label} key={index}>
            {product.Label}
          </option>
        ))}
      </Form.Select>
      <Row className="align-items-end m-0">
        <Col xs={6} md={6} className="p-0 align-items-end">
          {/* <div className='text-orange m-0'>Heb je interesse in een
            specifiek product</div> */}
          {env.Country !== 'NL' && <div>
            <InputField
              isRequired={env.Country === 'CUW' || env.Country === 'SXM'}
              isValidated={setValidated}
              handleChange={(e: string) => setCoCNumber(e)}
              label={translate('COC Number')}
              type="number"
              labelClasses="level-input"
              defaultValue={coCNumber}
            />
          </div>}
        </Col>
        <Col xs={6} md={6}>
          <div className="row m-0 justify-content-end">
            <div>
              <p className={validated ? 'active-text' : 'disabled-text'}>
                {translate('continue_alt')}
              </p>
            </div>
          </div>
          <div className="row m-0 justify-content-end">
            <button
              disabled={!validated}
              onClick={handleSubmit}
              className="submit-btn mr-4"
            >
              {/* <svg
                width='59'
                height='60'
                viewBox='0 0 59 60'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='29.5'
                  cy='29.8715'
                  r='29.5'
                  fill={validated ? '#5EC369' : '#5ec36a81'}
                />
                <path
                  d='M36.7266 31.473L30.1641
              38.348C29.9688 38.5433 29.7344
              38.6215 29.5 38.6215C29.2266
                38.6215 28.9922 38.5433 28.7969
                38.348L22.2344 31.473C21.8828
                  31.0824 21.8828 30.4965 22.2734
                  30.1449C22.625 29.7933 23.25
                    29.7933 23.6016 30.184L28.5625
                    35.3793V22.098C28.5625 21.5511
                      28.9531 21.1605 29.4609
                      21.1605C29.9297 21.1605
                        30.4375 21.5511 30.4375
                        22.098V35.3793L35.3594
                          30.184C35.7109 29.7933
                          36.3359 29.7933 36.6875
                            30.1449C37.0781 30.4965
                            37.0781 31.0824 36.7266
                              31.473Z'
                  fill='white'
                />
              </svg> */}
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="30"
                  cy="30"
                  r="30"
                  fill={validated ? '#5EC369' : '#5ec36a81'}
                />
                <path
                  opacity="0.997"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M39 27.7623C39 27.7651
                   39 27.7678 39 27.7706C36.5958 30.3243 34.1926
                    32.8798 31.7905 35.4368C31.4688 35.7668 31.085
                     35.9546 30.6391 36C30.5386 36 30.4382 36
                      30.3377 36C29.9301 35.9585 29.5721 35.7955
                       29.2636 35.5108C26.8458 32.9343 24.4246
                        30.3625 22 27.7952C22 27.776 22 27.7568
                         22 27.7377C22.8604 26.8223 23.7232
                          25.9098 24.5886 25C26.5294 27.0607
                           28.4689 29.1228 30.4073 31.1865C30.4367
                            31.211 30.4651 31.237 30.4923
                             31.2646C32.4562 29.1792 34.4189 27.0924
                              36.3805 25.0041C36.3882 24.9986 36.3959
                               24.9986 36.4036 25.0041C37.2681 25.9252
                                38.1335 26.8446 39 27.7623Z"
                  fill="#FEFFFE"
                />
              </svg>
            </button>
          </div>
        </Col>
      </Row>
    </Card.Body>
  );
};

export default CreditForm;
