/* eslint-disable max-len */
// import useLocales from '@/hooks/useLocales';
import * as React from 'react';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Form from 'react-bootstrap/Form';
import useLocales from '@/hooks/useLocales';

type Props = {
  handleChange?: (value) => void;
  handleFocus?: (value) => void;
  isValidated?: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
  isRequired?: boolean;
  type: string;
  defaultValue?: string;
  styles?: React.CSSProperties;
  classes?: string;
  labelClasses?: string;
  pattern?: string;
  compareEmail?: string;
};

const InputField = ({
  handleChange,
  label,
  type,
  defaultValue,
  labelClasses,
  isRequired,
  handleFocus,
  styles,
  isValidated,
  classes,
  compareEmail,
}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const [isSame, setIsSame] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>('');
  const {translate} = useLocales();

  React.useEffect(() => {
    setValue(`${defaultValue}`);
    if (defaultValue) setValidated(true);
  }, [defaultValue]);

  const handleTextChange = (event) => {
    const {value} = event.target;
    setValue(value);
    handleChange && handleChange(value);
  };

  const handleLostFocus = (event) => {
    const {value} = event.target;
    let regex = null;
    if (type === 'text') regex = /\p{L}/gu; // letters, spaces and hyphens
    if (type === 'initial') regex = /\p{L}/gu; // letters, spaces and periods
    if (type === 'number') regex = /^\d+$/; // only digits
    if (type === 'email') {
      regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (compareEmail === value) {
        setValidated(false);
        isValidated(false);
      }
    } // email validation
    if (type === 'tel') regex = /^\+?(\d\s*){9,11}\d$/; // 8-11 digists with spaces
    if (type === 'postcode') regex = /^\s*\d{4}(\s*-?\s*[A-Z]{2})\s*$/i; // 4digits,2letters,spaces,-
    if (type === 'all') regex = /./; // all including special characters
    if (value.length < 1) {
      setValidated(false);
      isValidated(false);
    } else if (!regex.test(value)) {
      setValidated(false);
      isValidated(false);
    } else {
      if (compareEmail === value.toLowerCase()) {
        setValidated(false);
        isValidated(false);
        setIsSame(true);
      } else {
        setValidated(true);
        setIsSame(false);
        handleChange && handleChange(value);
        handleFocus && handleFocus(value);
      }
    }
  };

  return (
    <Form.Group controlId="validationCustom04" className="p-0">
      <FloatingLabel
        controlId="floatingInput"
        label={label}
        className={`mb-3 ${labelClasses}`}
      >
        <Form.Control
          required={isRequired}
          type="text"
          value={value}
          style={styles}
          className={`${
            !validated && value && isRequired ?
              'is-invalid' :
              value && validated && isRequired ?
              'is-valid' :
              ''
          } ${classes}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e)
          }
          placeholder={label}
          onBlur={(e) => handleLostFocus(e)}
        />
      </FloatingLabel>
      {type === 'email' && isSame && !validated && value && isRequired && <span className='text-error-field'>
        {translate('same_email_used')}
      </span>}
    </Form.Group>
  );
};

export default InputField;
