/* eslint-disable max-len */
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import {useSelector} from 'react-redux';
import {stateType, MetaDataProps} from '@/types/state';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputField from '@/components/common/InputField';
import store, {setLeadData, setSelectedProduct} from '@/store';
import {authHttp} from '@/apis/axiosHttp';
// import apiHttp from '@/apis/axiosApi';
import toast from 'react-hot-toast';
import useLocales from '@/hooks/useLocales';
import env from '@/constants/env';
import TagManager from 'react-gtm-module';
import {findCallNumByEnv} from '@/components/utils/helper';

type Props = {
  handleDone: (number: number) => void;
  getData: ({loan, year, interestRate}) => void;
};

const durationMap: Record<string, number> = {
  NL: 5,
  SXM: 6,
  BES: 5,
  default: 6, // Default value if no match is found
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const CreditForm = ({handleDone, getData}: Props) => {
  const inputLoanRef = React.useRef(null);
  const inputYearRef = React.useRef(null);
  const state = useSelector((state: stateType) => state);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [year, setYear] = React.useState<number>(
    state.lead.Duration__c ?
    Number(state.lead.Duration__c ) : durationMap[env.Country] ||
    durationMap.default);
  const [newYear, setNewYear] = React.useState<number>(50);
  const [loan, setLoan] = React.useState<number>(
    state.lead.RequestedCreditAmount__c ?
    Number(state.lead.RequestedCreditAmount__c ): env.Country !== 'NL' ?
  25000 : 20000);
  let [valueLoan, setValue] = React.useState<number>(50);
  const [monthlyPayment, setMonthlyPayment] = React.useState<number>(0);
  const [maxYearValue, setMaxYearValue] = React.useState(0);
  const [coCNumber, setCoCNumber] = React.useState<string>(
    state.lead.COC_Number__c ?
    state.lead.COC_Number__c : '');
  const progressYear = (newYear / 100) * 100 + '%';
  const active = '#5EC369';
  const inactive = '#002172';
  const styleLoanInput = {
    background: `linear-gradient(90deg, ${active} 0% ${valueLoan}%,
        ${inactive} ${valueLoan}% 100%)`,
  };
  const styleYearInput = {
    background: `linear-gradient(90deg, ${active} 0% ${progressYear},
       ${inactive} ${progressYear} 100%)`,
  };
  const [product, setProduct] = React.useState<string>(
    state.lead.Proposition__c ?
    state.lead.Proposition__c : '');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setLoanMoney] = React.useState<number>(0);
  const [loanInterest, setLoanInterest] = React.useState<number>(0);
  const [interestRate, setInterestRate] = React.useState<number>(8.75);
  const [products, setProducts] = React.useState<MetaDataProps[]>([]);
  // const options = [];
  const {translate} = useLocales();
  // console.log(loanMoney);
  const firstQuadraticValue = env.Country !== 'NL' ? 44555.6 : 46777.8;
  const secondQuadraticValue = env.Country !== 'NL' ? 50500 : 57166.7;
  const thirdQuadraticFactor = env.Country !== 'NL' ? 500 :
    390;

    const logslider = (position) => {
      // position will be between 0 and 100
      const minp = 0;
      const maxp = 100;

      // The result should be between 1 and 20
      const minv = Math.log(1);
      const maxv = Math.log(20);

      // calculate adjustment factor
      const scale = (maxv - minv) / (maxp - minp);

      return Math.round(Math.exp(minv + scale * (position - minp)));
    };

    const logposition = (value) => {
      // value will be between 1 and 20
      const minv = Math.log(1);
      const maxv = Math.log(20);

      // The result should be between 0 and 100
      const minp = 0;
      const maxp = 100;

      // calculate adjustment factor
      const scale = (maxv+1 - minv) / (maxp - minp);

      return Math.round((Math.log(value) - minv) / scale + minp);
    };

    const handleNewYearChange = (event) => {
      const inputValue = parseInt(event.target.value);
      // Calculate the max duration based on the logarithmic scale
      setYear(logslider(inputValue));
      setNewYear(inputValue);
      const progress = (inputValue / 100) * 100 + '%';
      const payload = {
        Duration__c: logslider(inputValue),
      };
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
      const newBackgroundStyle = `linear-gradient(90deg, ${active} 0%
         ${progress}%,
         ${inactive} ${progress}% 100%)`;
      if (inputYearRef.current?.style)
        inputYearRef.current.style.background = newBackgroundStyle;
    };

    const transform = (value) => {
      let newValue = 0;
      if (value < 50) {
        newValue = thirdQuadraticFactor * value + 500;
      } else {
        value = value / 33.33333333333333333;
        newValue = Math.floor(
          firstQuadraticValue * Math.pow(value, 2) - secondQuadraticValue *
          value + 500,
        );
      }
      return Math.floor(newValue / 100) * 100;
    };

    const solveQuadratic = (y) => {
      const b = -secondQuadraticValue;
      const c = 500 - y;
      const discriminant = b ** 2 - 4 * firstQuadraticValue * c;

      if (discriminant < 0) {
        // The quadratic equation has no real solutions for the given value of y
        return NaN;
      }

      const x1 = (-b + Math.sqrt(discriminant)) / (2 * firstQuadraticValue);
      const x2 = (-b - Math.sqrt(discriminant)) / (2 * firstQuadraticValue);

      // Return the bigger of the two solutions
      return Math.max(x1, x2);
    };

    const transformBack = (value) => {
      let newValue = 0;
      const theMedian = env.Country !== 'NL' ? 25000 : 20000;
      if (value <= theMedian) {
        newValue = (value - 500) / thirdQuadraticFactor;
      } else {
        newValue = solveQuadratic(value);
        newValue = newValue * (100 / 3);
      }
      return Math.round(newValue);
    };

    const handleLoanChange = () => () => {
      const inputs = JSON.parse(
        (document.getElementById('sliderId') as HTMLInputElement).value,
      );
      valueLoan = transform(inputs);
      setLoan(valueLoan);
      setValue(inputs);
      const progress = (inputs / 100) * 100 + '%';
      const payload = {
        RequestedCreditAmount__c: valueLoan,
      };
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
      const newBackgroundStyle = `linear-gradient(90deg, ${active} 0%
        ${progress}%,
        ${inactive} ${progress}% 100%)`;
     inputLoanRef.current.style.background = newBackgroundStyle;
    };

  const convertEuro = (numb: number) => {
    let value = null;
    switch (env.Country) {
      case 'NL':
        value = new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
        }).format(Number(numb));
      break;
      case 'ABW':
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'AWG',
        }).format(Number(numb));
      break;
      case 'CUW':
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'ANG',
        }).format(Number(numb));
      break;
      default:
        value = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(Number(numb));
        break;
    }
    return value;
  };

  const handleLoanText = (event) => {
    const inputAmountValue = document.getElementById(
      'floatingInput',
    ) as HTMLInputElement;
    let valueText = 0;
    const eventReplaceDot = env.Country === 'NL' ? event
      .replace(/\./g, '')
      .replace(',', '.')
      // eslint-disable-next-line
      .replace(/[^0-9\.-]+/g, "") : event.replace(/[^0-9\.-]+/g, "");
    valueText =
      parseFloat(eventReplaceDot) >= 250000 ?
        250000 :
        parseFloat(eventReplaceDot);
    valueLoan = transformBack(valueText);
    setTimeout(() => (inputAmountValue.value = convertEuro(valueText)), 100);
    setLoan(valueText);
    setValue(valueLoan);
    const payload = {
      RequestedCreditAmount__c: valueText,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
  };
  const tagManagerArgs = {
    gtmId: env.GtmId,
    dataLayer: {
      'event': 'Krediet aanvraag stap',
      'stap_aanvraag': 'Kredietinformatie',
      'type_aanvraag': product,
      'leenbedrag': loan,
      'looptijd': year,
    },
  };
  const addDataLayer = () => {
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem('loanInfo', JSON.stringify(tagManagerArgs.dataLayer));
  };


  const handleYearChange = (event) => {
    const value = Number(event.target.value);
    const progress = (logposition(value) / 100) * 100 + '%';
    setYear(value);
    setNewYear(logposition(value));
    const payload = {
      Duration__c: value,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0%
       ${progress}%,
       ${inactive} ${progress}% 100%)`;
    if (inputYearRef.current?.style)
      inputYearRef.current.style.background = newBackgroundStyle;
  };

  React.useEffect(() => {
    const interest = interestRate / 100 / 12;
    const months = year * 12;
    setLoanInterest(Number((loan * interest).toFixed(2))); // amount of interest per year
    const x = Math.pow(1 + interest, months); // monthly figure
    setMonthlyPayment((loan * x * interest) / (x - 1));
    setLoanMoney(Number(monthlyPayment * months - loan)); // total amount to be paid
    getData({year, loan, interestRate});
    if (year > 0 && loan > 0 && product) setValidated(true);
    else setValidated(false);
  }, [year, loan, loanInterest, interestRate, product, monthlyPayment, getData]);

  React.useEffect(() => {
    // const searchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(Array.from(searchParams.entries()));
    if (!product) {
      const prod = state.metaData.filter((el) => !!el.InterestPercentage__c);
      // Find the index of the element with the specific id
      const indexToMove = prod.findIndex(
        (obj) => obj.Label === 'Zakelijk Krediet',
      );

      // If the element is found, move it to the beginning of the array
      if (indexToMove !== -1) {
        const elementToMove = prod.splice(indexToMove, 1)[0];
        prod.unshift(elementToMove);
      }
      setProducts(prod);
      const chosen = state.lead.Proposition__c || product;
      const prodfinal = prod.filter((el) => el.Label ===
      chosen);
      setProduct(prodfinal[0]?.Label || prod[0]?.Label);
      setInterestRate(prodfinal[0]?.InterestPercentage__c ||
        prod[0]?.InterestPercentage__c);
      store.dispatch(setSelectedProduct(prodfinal[0] || prod[0]));
      setLoan(state.lead.RequestedCreditAmount__c);
      setYear(state.lead.Duration__c);
    }
  }, [product, state]);

  React.useEffect(() => {
    const prod = products.filter((el) => el.Label === product);
    setProduct(prod[0]?.Label);
    setInterestRate(prod[0]?.InterestPercentage__c || 0);
    store.dispatch(setSelectedProduct(prod[0]));
  }, [product, products]);

  // const loanLogic = (e: number) => {
  //   if (e <= 0) return e <= 0 ? 0 : e;
  //   else return e >= 100 ? 100 : e;
  // };

  React.useEffect(() => {
    if (coCNumber) {
      const payload = {
        COC_Number__c: coCNumber,
      };
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    }
  }, [coCNumber]);

  React.useEffect(() => {
    handleLoanText(`${loan}`);
    handleYearChange({
      target: {
        value: year,
      },
    });
    setMaxYearValue(20);
    // setMinYearValue(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // for (let i = 120; i >= 1; i -= 12) {
  //   options.push(<option value={i}>{i} maanden</option>);
  // }

  const updateLead = () => {
    const toastId = toast.loading(translate('loading'));
    const payload = {
      RequestedCreditAmount__c: loan,
      Duration__c: Number(year) * 12,
      Proposition__c: product,
    };
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      ...payload,
    })
    // apiHttp
    //   .create('api/salesforce/', {
    //     method: 'PATCH',
    //     path: `services/data/v57.0/sobjects/Lead/${store.getState().id}`,
    //     headers: [
    //       {
    //         key: 'Content-Type',
    //         value: 'application/json',
    //       },
    //     ],
    //     body: payload,
    //   })
      .then(() => {
        store.dispatch(setLeadData({...store.getState().lead, ...payload}));
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
      });
  };

  const handleSubmit = () => {
    const payload = {
      RequestedCreditAmount__c: loan,
      Duration__c: Number(year) * 12,
      Proposition__c: product,
      COC_Number__c: coCNumber,
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    addDataLayer();
    handleDone(1);
    if (store.getState().id) updateLead();
  };

  return (
    <Card.Body className="mobile_form pb-5 pt-3">
      <div className="mobile_header">{translate('what_you_need')}</div>
      <div className="mobile_details">
        <p>{translate('fill_as_many_fields')}</p>
      </div>
      <Row className="mb-3 align-items-center">
        <Form.Group controlId="validationCustom01">
          <Row className="align-items-center mb-2">
            <Col xs={5} md={5} className="col-5 row m-0 mt-3 align-items-end">
              <Form.Label>{translate('loan_amt')}</Form.Label>
            </Col>
            <Col
              xs={7}
              md={7}
              className="col-7 row m-0
             justify-content-end p-0"
            >
              <InputField
                handleFocus={(e: string) => handleLoanText(e)}
                type="all"
                defaultValue={convertEuro(loan)}
                styles={{textAlign: 'right'}}
              />
              {/* <div className="input-group">
                <input type="text" className="form-control"
                  value={convertEuro(loan)}
                  onChange={handleLoanChange(minLoanValue, maxLoanValue)}
                />
              </div> */}
            </Col>
          </Row>
          <input
            ref={inputLoanRef}
            id="sliderId"
            className="inputR"
            name="sliderName"
            type="range"
            min={0}
            max={100}
            value={valueLoan}
            onChange={handleLoanChange()}
            style={styleLoanInput}
          />
        </Form.Group>
      </Row>
      <Row className="mb-5">
        <Form.Group controlId="validationCustom01">
          <Row className="align-items-end mb-3">
            <Col xs={6} md={6} className="col-6 row m-0 align-items-end">
              <Form.Label>{translate('duration')}</Form.Label>
            </Col>
            <Col xs={6} md={6} className="col-6 text-end">
              <Form.Select
                className="short_select"
                aria-label="Select year"
                value={year}
                onChange={(e) => handleYearChange(e)}
              >
                {Array(maxYearValue)
                  .fill(0)
                  .map((_, index) => (
                    <option value={index + 1} key={index}>
                      {' '}
                      {index + 1} {translate('year')}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Row>
          <input
            type="range"
            id="sliderIds"
            className="inputR mt-6"
            name="sliderName"
            min="1"
            max="100"
            value={newYear}
            onChange={handleNewYearChange}
            step="1"
            style={styleYearInput}
          />
        </Form.Group>
      </Row>
      <Form.Select
        className="full_select"
        aria-label="Select type"
        value={product}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setProduct(e.target.value)
        }
      >
        {products.map((product, index) => (
          <option value={product.Label} key={index}>
            {product.Label}
          </option>
        ))}
      </Form.Select>
      <Form.Group>
      {env.Country !== 'NL' && <div>
            <InputField
              isRequired={env.Country === 'CUW' || env.Country === 'SXM'}
              isValidated={setValidated}
              handleChange={(e: string) => setCoCNumber(e)}
              label={translate('COC Number')}
              type="number"
              labelClasses="level-input"
              defaultValue={coCNumber}
            />
          </div>}
      </Form.Group>
      <div className="mobile_details">
        <p>{translate('loan_amount_determined')}</p>
        <Row className="align-items-center">
          <Col xs={7} md={7} className="col-7">
            <span>{translate('payable_per_month')}*</span>
          </Col>
          <Col xs={5} md={5} className="col-5 text-end">
            <span>{convertEuro(monthlyPayment)}</span>
          </Col>
        </Row>
        {/* <Row className="align-items-center">
          <Col xs={7} md={7} className="col-7">
            <span>
              {translate('costs_interests')} ({interestRate}%)*
            </span>
          </Col>
          <Col xs={5} md={5} className="col-5 text-end">
            <span>{convertEuro(loanMoney || 0)}</span>
          </Col>
        </Row> */}
        {/* <Row className="align-items-center">
          <Col xs={7} md={7} className="col-7">
            <span>{translate('total_paid')}*</span>
          </Col>
          <Col xs={5} md={5} className="col-5 text-end">
            <span>
              {convertEuro(Number(monthlyPayment * (year * 12) || 0))}
            </span>
          </Col>
        </Row> */}
      </div>
      <div className="mobile_details mb-4 w-60 text-end">
        *{translate('based_on_interest')} {interestRate}%.
      </div>
      <div>
        <div>
          <button
            type="submit"
            className="btn btn-success
           w-100 mobile_button"
            disabled={!validated}
            onClick={handleSubmit}
          >
            {translate('start_application')}
          </button>
        </div>
        <div>
          <button className="btn btn-primary w-100 mobile_button" onClick={() => window.open(`tel:${findCallNumByEnv()}`)}>
            <svg
              width="43"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.957 17.3789L20.9258 21.7188C20.7969
               22.3633 20.2812 22.793 19.6367 22.793C8.80859 22.75 0
                13.9414 0 3.11328C0 2.46875 0.386719 1.95312 1.03125
                 1.82422L5.37109 0.792969C5.97266 0.664062 6.61719
                  1.00781 6.875 1.56641L8.89453 6.25C9.10938 6.80859
                   8.98047 7.45312 8.50781 7.79688L6.1875 9.6875C7.64844
                    12.6523 10.0547 15.0586 13.0625 16.5195L14.9531
                     14.1992C15.2969 13.7695 15.9414 13.5977 16.5
                      13.8125L21.1836 15.832C21.7422 16.1328 22.0859
                       16.7773 21.957 17.3789Z"
                fill="white"
              />
            </svg>
            {translate('call')}
          </button>
        </div>
      </div>
    </Card.Body>
  );
};

export default CreditForm;
