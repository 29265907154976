/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import {useSelector} from 'react-redux';
import {stateType} from '@/types/state';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import apiHttp from '@/apis/axiosApi';
import {authHttp} from '@/apis/axiosHttp';
import toast from 'react-hot-toast';
import store, {setID, setLeadData, getUser} from '@/store';
import InputField from '@/components/common/InputField';
import useLocales from '@/hooks/useLocales';
import TagManager from 'react-gtm-module';
import {findContactFormLink, phoneNumByEnv} from '@/components/utils/helper';
import env from '@/constants/env';

type Props = {
  handleDone: ({key}) => void;
  aanvraag?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const ContactForm = ({handleDone, aanvraag}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const state = useSelector((state: stateType) => state);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [selectBtn, setSelectBtn] = React.useState<string>(null);
  const [firstName, setFirstName] = React.useState<string>('');
  const [secondName, setSecondName] = React.useState<string>('');
  const [initials, setInitials] = React.useState<string>('');
  const leadInfo = state.lead;
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>(phoneNumByEnv());
  const {translate} = useLocales();
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setLoading(false);
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(Array.from(searchParams.entries()));
    const payload = {
      FirstName: firstName,
      LastName: secondName,
      Email: email,
      Phone: phone,
      Gender__c: selectBtn,
      Lead_Scoring__c: 'Cold',
      Agree_to_Privacy_Policy__c: checked,
      RequestedCreditAmount__c: store.getState().loan,
      Duration__c: Number(store.getState().year) * 12,
      Status: 'Incomplete',
      Initials__c: initials,
      Google_CID__c: getCookie('_ga'),
      // RT_developer_name__c: 'Credit Application',
      Country: env.Country === 'NL' ? 'NLD' : env.Country || 'NLD',
      AccountId__c: leadInfo.AccountId__c,
      Company: `${firstName} ${secondName}`,
      COC_Number__c: leadInfo.COC_Number__c,
      Proposition__c: store.getState().selectedProduct.Label,
    };
    if (params.lead_id || store.getState().id) updateLead(payload);
    else createLead(payload);
  };

  const createLead = (payload) => {
    const toastId = toast.loading(translate('loading'));
    authHttp.post('api/salesforce/lead/', {
      ...payload,
      ApplicationData__c: JSON.stringify(payload),
    })
    .then((res) => {
      store.dispatch(setID(res.data.id));
      store.dispatch(setLeadData({...store.getState().lead, ...payload}));
      handleDone({
        key: !aanvraag ? 2 : 1,
        });
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
        store.dispatch(getUser(res.data.id));
        dataLayerHandler(res);
        setLoading(false);
  }).catch(() => {
    toast.error(translate('details_fail_submission'), {
      id: toastId,
    });
  })
  .finally(() => {
    setLoading(false);
  });
    // apiHttp
    //   .create('api/salesforce/', {
    //     method: 'POST',
    //     path: 'services/data/v57.0/sobjects/Lead',
    //     headers: [
    //       {
    //         key: 'Content-Type',
    //         value: 'application/json',
    //       },
    //     ],
    //     body: {
    //       ...payload,
    //       ApplicationData__c: JSON.stringify(payload),
    //     },
    //   })
    //   .then((res) => {
    //     handleDone({
    //       key: !aanvraag ? 2 : 1,
    //     });
    //     store.dispatch(setID(res.data.id));
    //     store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    //     toast.success(translate('submitted_details_success'), {
    //       id: toastId,
    //     });
    //     store.dispatch(getUser(res.data.id));
    // dataLayerHandler(res);
    // }).catch(() => {
    //   toast.error(translate('details_fail_submission'), {
    //     id: toastId,
    //   });
    // })
    // .finally(() => {
    //   toast.dismiss();
    //   setLoading(false);
    // });
  };

  const dataLayerHandler = (res) => {
    const existingLoanInfo = JSON.parse(localStorage.getItem('loanInfo')) || {};
    const updatedDataLayer = {
      ...existingLoanInfo,
      mailadres: email,
      stap_aanvraag: 'Contactgegevens',
      lead_id: setID(res.data.id).payload,
    };
    const tagManagerArgs = {
      gtmId: env.GtmId,
      dataLayer: updatedDataLayer,
    };
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem('loanInfo', JSON.stringify({
      dataLayer: updatedDataLayer,
    }));
  };

  const updateLead = (payload) => {
    const toastId = toast.loading(translate('loading'));
    handleDone({
      key: !aanvraag ? 2 : 1,
    });
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      ...payload,
    })
    // apiHttp
    //   .create('api/salesforce/', {
    //     method: 'PATCH',
    //     path: `services/data/v57.0/sobjects/Lead/${store.getState().id}`,
    //     headers: [
    //       {
    //         key: 'Content-Type',
    //         value: 'application/json',
    //       },
    //     ],
    //     body: payload,
    //   })
      .then(() => {
        store.dispatch(setLeadData({...store.getState().lead, ...payload}));
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
        setLoading(false);
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
        setLoading(false);
      });
  };

  React.useEffect(() => {
    // const name = leadInfo.FirstName;
    // if (name) {
      setFirstName(leadInfo.FirstName || '');
      setSecondName(leadInfo.LastName || '');
      setEmail(leadInfo.Email || '');
      setSelectBtn(leadInfo.Gender__c || '');
      setPhone(leadInfo.Phone || '');
      setInitials(leadInfo.Initials__c || '');
      setChecked(leadInfo.Agree_to_Privacy_Policy__c);
    // }
  }, [leadInfo]);

  React.useEffect(() => {
    const payload = {
      FirstName: firstName,
      LastName: secondName,
      Email: email,
      Phone: phone,
      Gender__c: selectBtn,
      Lead_Scoring__c: 'Cold',
      Agree_to_Privacy_Policy__c: checked,
      RequestedCreditAmount__c: store.getState().loan,
      Duration__c: store.getState().year,
      Initials__c: initials,
      Application_Submitted__c: 'New',
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    if (
      firstName &&
      secondName &&
      selectBtn &&
      email &&
      phone &&
      checked &&
      initials
    )
      setValidated(true);
    else setValidated(false);
  }, [firstName, secondName, selectBtn, email, phone, checked, initials]);

  return (
    <Card.Body className="desktop_form">
      {/* <p className="mt-4 mb-3 mobile_details">
        {translate('already_have_acc_login')} &nbsp;
        <span>
          <a className="text-warning"
            href="https://portal.mijnqredits.nl/"
            rel="noreferrer" target='_blank'
          >{translate('my_qredits')}</a></span>
      </p> */}
      <Form>
        <Row className="mb-3 mt-3 align-items-center">
          <Form.Group controlId="validationCustom01">
            <ButtonGroup aria-label="Basic example">
              <Button
                variant={
                  selectBtn === 'Female' ? 'outline-success' : 'outline-primary'
                }
                onClick={() => setSelectBtn('Female')}
              >
                {translate('mrs')}
              </Button>
              <Button
                variant={
                  selectBtn === 'Male' ? 'outline-success' : 'outline-primary'
                }
                onClick={() => setSelectBtn('Male')}
              >
                {translate('mr')}
              </Button>
              {/* <Button
                variant={
                  selectBtn === 'Neutral' ?
                    'outline-success' :
                    'outline-primary'
                }
                onClick={() => setSelectBtn('Neutral')}
              >
               {translate('other')}
              </Button> */}
            </ButtonGroup>
          </Form.Group>
        </Row>
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setFirstName(e)}
          label={translate('f_name')}
          type="text"
          defaultValue={firstName}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setInitials(e)}
          label={translate('initials')}
          type="initial"
          defaultValue={initials}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setSecondName(e)}
          label={translate('l_name')}
          type="text"
          defaultValue={secondName}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setEmail(e)}
          label={translate('email')}
          type="email"
          defaultValue={email}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setPhone(e)}
          label={translate('phone')}
          type="tel"
          defaultValue={phone}
        />
        <Form.Group controlId="validationCustom05" className="flex">
          <Form.Check
            required
            type="checkbox"
            id="default-checkbox"
            checked={checked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setChecked(e.target.checked)
            }
            label={
              <>
                {translate('agree_TOC')}
                <a
                  target="_blank"
                  href={findContactFormLink()}
                  rel="noreferrer"
                  style={{textDecoration: 'none'}}
                >
                  &#128279;
                </a>
              </>
            }
          />
        </Form.Group>
        <Row className="align-items-end m-0">
          <Col xs={6} md={6} className="p-0"></Col>
          <Col xs={6} md={6}>
            <div className="row m-0 justify-content-end">
              <div>
                <p className={validated ? 'active-text' : 'disabled-text'}>
                  {translate('continue_alt')}
                </p>
              </div>
            </div>
            <div className="row m-0 justify-content-end">
              <button
                type="submit"
                disabled={!validated || loading}
                className="submit-btn mr-4"
                onClick={handleSubmit}
              >
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="30"
                    cy="30"
                    r="30"
                    fill={validated && !loading ? '#5EC369' : '#5ec36a81'}
                  />
                  <path
                    opacity="0.997"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39 27.7623C39 27.7651
                   39 27.7678 39 27.7706C36.5958 30.3243 34.1926
                    32.8798 31.7905 35.4368C31.4688 35.7668 31.085
                     35.9546 30.6391 36C30.5386 36 30.4382 36
                      30.3377 36C29.9301 35.9585 29.5721 35.7955
                       29.2636 35.5108C26.8458 32.9343 24.4246
                        30.3625 22 27.7952C22 27.776 22 27.7568
                         22 27.7377C22.8604 26.8223 23.7232
                          25.9098 24.5886 25C26.5294 27.0607
                           28.4689 29.1228 30.4073 31.1865C30.4367
                            31.211 30.4651 31.237 30.4923
                             31.2646C32.4562 29.1792 34.4189 27.0924
                              36.3805 25.0041C36.3882 24.9986 36.3959
                               24.9986 36.4036 25.0041C37.2681 25.9252
                                38.1335 26.8446 39 27.7623Z"
                    fill="#FEFFFE"
                  />
                </svg>
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card.Body>
  );
};

export default ContactForm;
