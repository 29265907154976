import env from '@/constants/env';

export const findLangByEnv = () => {
  let currentLang;
  switch (env.Country) {
    case 'ABW':
      currentLang = 'en';
      break;
    case 'SXM':
      currentLang = 'en';
      break;
    case 'CUW':
      currentLang = 'en';
      break;
    case 'BES':
      currentLang = 'en';
      break;
    case 'NL':
      currentLang = 'nl';
      break;
    default:
      currentLang = 'nl';
      break;
  }
  return currentLang;
};

export const findCountryByEnv = () => {
  let currentCountry;
  switch (env.Country) {
    case 'ABW':
      currentCountry = 'Aruba';
      break;
    case 'SXM':
      currentCountry = 'Sint Maarten';
      break;
    case 'CUW':
      currentCountry = 'Curaçao';
      break;
    case 'BES':
      currentCountry = 'Bonaire';
      break;
    case 'NL':
      currentCountry = 'Belgium';
      break;
    default:
      currentCountry = 'Belgium';
      break;
  }
  return currentCountry;
};

export const phoneNumByEnv = () => {
  let phoneNum;
  switch (env.Country) {
    case 'ABW':
      phoneNum = '+297 ';
      break;
    case 'SXM':
      phoneNum = '+721 ';
      break;
    case 'CUW':
      phoneNum = '+599 ';
      break;
    case 'BES':
      phoneNum = '+599 ';
      break;
    case 'NL':
      phoneNum = '';
      break;
    default:
      phoneNum = '';
      break;
  }
  return phoneNum;
};

export const linkByEnv = () => {
  let link;
  switch (env.Country) {
    case 'ABW':
      link = 'https://aruba-en.qredits.com/';
      break;
    case 'SXM':
      link = 'https://stmaarten-en.qredits.com/';
      break;
    case 'CUW':
      link = 'https://curacao-nl.qredits.com/';
      break;
    case 'BES':
      link = 'https://bonaire-nl.qredits.com/';
      break;
    case 'NL':
      link = 'http://qredits.nl/';
      break;
    default:
      link = 'http://qredits.nl/';
      break;
  }
  return link;
};

export const findCallNumByEnv = () => {
  let currentCallNum;
  switch (env.Country) {
    case 'ABW':
      currentCallNum = '+297 582 2250';
      break;
    case 'SXM':
      currentCallNum = '+17215423428';
      break;
    case 'CUW':
      currentCallNum = '+599 9 465 8582';
      break;
    case 'BES':
      currentCallNum = '+599 717 1412';
      break;
    case 'NL':
      currentCallNum = '0546-534010';
      break;
    default:
      currentCallNum = '0546-534010';
      break;
  }
  return currentCallNum;
};

export const findMyQreditsLink = () => {
  let currentQlink;
  switch (env.Country) {
    case 'ABW':
      currentQlink = 'https://portal.mijnqredits.nl/caribbeancommunity';
      break;
    case 'SXM':
      currentQlink = 'https://portal.mijnqredits.nl/caribbeancommunity';
      break;
    case 'CUW':
      currentQlink = 'https://portal.mijnqredits.nl/caribbeancommunity';
      break;
    case 'BES':
      currentQlink = 'https://portal.mijnqredits.nl/caribbeancommunity';
      break;
    case 'NL':
      currentQlink = 'https://portal.mijnqredits.nl/';
      break;
    default:
      currentQlink = 'https://portal.mijnqredits.nl/';
      break;
  }
  return currentQlink;
};

export const findContactFormLink = () => {
  let contactFormLink;
  switch (env.Country) {
    case 'ABW':
      contactFormLink =
        'https://aruba-nl.qredits.com/service/algemene-voorwaarden.html';
      break;
    case 'SXM':
      contactFormLink =
        'https://stmaarten-nl.qredits.com/service/algemene-voorwaarden.html';
      break;
    case 'CUW':
      contactFormLink =
        'https://curacao-nl.qredits.com/service/algemene-voorwaarden.html';
      break;
    case 'BES':
      contactFormLink =
        'https://bonaire-nl.qredits.com/service/algemene-voorwaarden.html';
      break;
    case 'NL':
      contactFormLink = 'https://qredits.nl/service/algemene-voorwaarden.html?gad=1&gclid=CjwKCAjw3oqoBhAjEiwA_UaLtmHYAVW4h6SEFIA-BPQVRPD7AgFNg6KWLN-KItimts85QZUXPU0Z-hoCBrAQAvD_BwE';
      break;
    default:
      contactFormLink = 'https://qredits.nl/service/algemene-voorwaarden.html?gad=1&gclid=CjwKCAjw3oqoBhAjEiwA_UaLtmHYAVW4h6SEFIA-BPQVRPD7AgFNg6KWLN-KItimts85QZUXPU0Z-hoCBrAQAvD_BwE';
      break;
  }
  return contactFormLink;
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};
