import * as React from 'react';
import Row from 'react-bootstrap/Row';
import {useSelector} from 'react-redux';
import {stateType} from '@/types/state';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {authHttp} from '@/apis/axiosHttp';
// import apiHttp from '@/apis/axiosApi';
import toast from 'react-hot-toast';
import store, {setID, setLeadData} from '@/store';
import InputField from '@/components/common/InputField';
import useLocales from '@/hooks/useLocales';
import {findContactFormLink, phoneNumByEnv} from '@/components/utils/helper';
import TagManager from 'react-gtm-module';
import env from '@/constants/env';

type Props = {
  handleDone: (number: number) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const ContactForm = ({handleDone}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const state = useSelector((state: stateType) => state);
  const leadInfo = state.lead;
  const [checked, setChecked] = React.useState<boolean>(false);
  const [selectBtn, setSelectBtn] = React.useState<string>(null);
  const [firstName, setFirstName] = React.useState<string>('');
  const [secondName, setSecondName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>(phoneNumByEnv());
  const [initials, setInitials] = React.useState<string>('');
  const {translate} = useLocales();
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  };

  const handleSubmit = () => {
    if (!validated) return;
    setLoading(true);
    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(Array.from(searchParams.entries()));
    const payload = {
      FirstName: firstName,
      LastName: secondName,
      Email: email,
      Phone: phone,
      Gender__c: selectBtn,
      Lead_Scoring__c: 'Cold',
      Agree_to_Privacy_Policy__c: checked,
      RequestedCreditAmount__c: store.getState().loan,
      Duration__c: Number(store.getState().year) * 12,
      Status: 'Incomplete',
      Initials__c: initials,
      Google_CID__c: getCookie('_ga'),
      AccountId__c: leadInfo.AccountId__c,
      Company: `${firstName} ${secondName}`,
      // RT_developer_name__c: 'Credit Application',
      Country: env.Country === 'NL' ? 'NLD' : env.Country || 'NLD',
      COC_Number__c: leadInfo.COC_Number__c,
      Proposition__c: store.getState().selectedProduct.Label,
    };
    if (params.lead_id || store.getState().id) updateLead(payload);
    else createLead(payload);
  };

  const createLead = (payload) => {
    const toastId = toast.loading(translate('loading'));
    authHttp.post('api/salesforce/lead/', {
      ...payload,
      ApplicationData__c: JSON.stringify(payload),
    })
    // apiHttp
    //   .create('api/salesforce/', {
    //     method: 'POST',
    //     path: 'services/data/v57.0/sobjects/Lead',
    //     headers: [
    //       {
    //         key: 'Content-Type',
    //         value: 'application/json',
    //       },
    //     ],
    //     body: {
    //       ...payload,
    //       ApplicationData__c: JSON.stringify(payload),
    //     },
    //   })
      .then((res) => {
        store.dispatch(setID(res.data.id));
        store.dispatch(setLeadData({...store.getState().lead, ...payload}));
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
        handleDone(2);
        dataLayerHandler(res);
        setLoading(false);
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
        setLoading(false);
      });
  };

  const dataLayerHandler = (res) => {
    const existingLoanInfo = JSON.parse(localStorage.getItem('loanInfo')) || {};
    // console.log('existingLoanInfo1', existingLoanInfo);

    const updatedDataLayer = {
      ...existingLoanInfo,
      mailadres: email,
      stap_aanvraag: 'Contactgegevens',
      lead_id: setID(res.data.id).payload,
    };
    const tagManagerArgs = {
      gtmId: env.GtmId,
      dataLayer: updatedDataLayer,
    };
    // console.log('existingLoanInfo2', existingLoanInfo);
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem(
      'loanInfo',
      JSON.stringify({
        dataLayer: updatedDataLayer,
      }),
    );
  };

  const updateLead = (payload) => {
    const toastId = toast.loading(translate('loading'));
    handleDone(2);
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      ...payload,
    })
    // apiHttp
    //   .create('api/salesforce/', {
    //     method: 'PATCH',
    //     path: `services/data/v57.0/sobjects/Lead/${store.getState().id}`,
    //     headers: [
    //       {
    //         key: 'Content-Type',
    //         value: 'application/json',
    //       },
    //     ],
    //     body: payload,
    //   })
      .then(() => {
        store.dispatch(setLeadData({...store.getState().lead, ...payload}));
        toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
      });
  };

  React.useEffect(() => {
    // const name = leadInfo.FirstName;
    // if (name) {
      setFirstName(leadInfo.FirstName || '');
      setSecondName(leadInfo.LastName || '');
      setEmail(leadInfo.Email || '');
      setSelectBtn(leadInfo.Gender__c || '');
      setPhone(leadInfo.Phone || '');
      setInitials(leadInfo.Initials__c || '');
      setChecked(leadInfo.Agree_to_Privacy_Policy__c);
    // }
  }, [leadInfo]);

  React.useEffect(() => {
    const payload = {
      FirstName: firstName,
      LastName: secondName,
      Email: email,
      Phone: phone,
      Gender__c: selectBtn,
      Lead_Scoring__c: 'Cold',
      Agree_to_Privacy_Policy__c: checked,
      RequestedCreditAmount__c: store.getState().loan,
      Initials__c: initials,
      Duration__c: store.getState().year,
      Application_Submitted__c: 'New',
    };
    store.dispatch(setLeadData({...store.getState().lead, ...payload}));
    if (
      firstName &&
      secondName &&
      selectBtn &&
      email &&
      phone &&
      checked &&
      initials
    )
      setValidated(true);
    else setValidated(false);
  }, [firstName, secondName, selectBtn, email, phone, checked, initials]);

  return (
    <Card.Body className="mobile_form pb-5 pt-3">
      <div className="mobile_header">{translate('contact_details')}</div>
      {/* <p className="mt-4 mb-0 mobile_details">
        {translate('have_account_log_to')} &nbsp;
        <span className="text-warning">{translate('my_qredits')}</span>
      </p>
      <p className="mt-1 mobile_details">
        {translate('is_intermediary')} &nbsp;
        <span className="text-warning">{translate('click_here')}</span>
      </p> */}
      <Form>
        <Row className="mb-3 align-items-center">
          <Form.Group controlId="validationCustom01">
            <ButtonGroup aria-label="Basic example">
              <Button
                variant={
                  selectBtn === 'Female' ? 'outline-success' : 'outline-primary'
                }
                onClick={() => setSelectBtn('Female')}
              >
                {translate('mrs')}
              </Button>
              <Button
                variant={
                  selectBtn === 'Male' ? 'outline-success' : 'outline-primary'
                }
                onClick={() => setSelectBtn('Male')}
              >
                {translate('mr')}
              </Button>
              {/* <Button
                variant={
                  selectBtn === 'Neutral' ?
                    'outline-success' :
                    'outline-primary'
                }
                onClick={() => setSelectBtn('Neutral')}
              >
                {translate('other')}
              </Button> */}
            </ButtonGroup>
          </Form.Group>
        </Row>
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setFirstName(e)}
          label={translate('f_name')}
          type="text"
          defaultValue={firstName}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setInitials(e)}
          label={translate('initials')}
          type="initial"
          defaultValue={initials}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setSecondName(e)}
          label={translate('l_name')}
          type="text"
          defaultValue={secondName}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setEmail(e)}
          label={translate('email')}
          type="email"
          defaultValue={email}
        />
        <InputField
          isRequired
          isValidated={setValidated}
          handleChange={(e: string) => setPhone(e)}
          label={translate('phone')}
          type="tel"
          defaultValue={phone}
        />
        <Form.Group controlId="validationCustom05">
          <Form.Check
            required
            type="checkbox"
            id="default-checkbox"
            checked={checked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setChecked(e.target.checked)
            }
            label={
              <>
                {translate('agree_TOC')}
                <a
                  target="_blank"
                  href={findContactFormLink()}
                  rel="noreferrer"
                  style={{textDecoration: 'none'}}
                >
                  &#128279;
                </a>
              </>
            }
          />
        </Form.Group>
      </Form>
      <div className="mt-4">
        <div>
          <button
            type="submit"
            className="btn btn-success
            w-100 mobile_button"
            disabled={!validated || loading}
            onClick={handleSubmit}
          >
            {translate('continue_alt')}
          </button>
        </div>
        <div>
          <button
            className="btn btn-outline-warning w-100 mobile_button"
            onClick={() => handleDone(0)}
          >
            {translate('previous_step')}
          </button>
        </div>
        <div>
          <button className="btn btn-primary w-100 mobile_button">
            <svg
              width="43"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.957 17.3789L20.9258 21.7188C20.7969
              22.3633 20.2812 22.793 19.6367 22.793C8.80859 22.75 0
                13.9414 0 3.11328C0 2.46875 0.386719 1.95312 1.03125
                1.82422L5.37109 0.792969C5.97266 0.664062 6.61719
                  1.00781 6.875 1.56641L8.89453 6.25C9.10938 6.80859
                  8.98047 7.45312 8.50781 7.79688L6.1875 9.6875C7.64844
                    12.6523 10.0547 15.0586 13.0625 16.5195L14.9531
                    14.1992C15.2969 13.7695 15.9414 13.5977 16.5
                      13.8125L21.1836 15.832C21.7422 16.1328 22.0859
                      16.7773 21.957 17.3789Z"
                fill="white"
              />
            </svg>
            {translate('call')}
          </button>
        </div>
      </div>
    </Card.Body>
  );
};

export default ContactForm;
