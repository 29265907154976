/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import store, {setDocsData} from '@/store';
import {authHttp} from '@/apis/axiosHttp';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useLocales from '@/hooks/useLocales';
import TagManager from 'react-gtm-module';
import env from '@/constants/env';

type FileProps = {
  file?: string;
  versionId?: string;
  Id: string;
  Name?: string;
  Body?: string;
  isRequired?: boolean;
};

type Props = {
  handleDone: (number: number) => void;
  aanvraag?: boolean;
  listFiles?: FileProps[];
  setListFiles?: any;
};

type ModalProps = {
  onHide: () => void;
  show: boolean;
  onDelete: () => void;
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const MyVerticallyCenteredModal = (props: ModalProps) => {
  const {translate} = useLocales();
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {translate('delete')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{translate('delete_file')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={props.onHide}>
         {translate('close')}
        </Button>
        <Button onClick={props.onDelete}>{translate('delete')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

const DocumentsForm = ({handleDone, aanvraag, listFiles,
  setListFiles}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(null);
  const [isUpload, setIsUpload] = React.useState<boolean>(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState<number>(null);
  const [deleteData, setDeleteData] = React.useState<{
    index: number;
    file: FileProps;
  }>(null);
  const {translate} = useLocales();


  const uploadFiles = async (step: number, name: string, file: File) => {
    const toastId = toast.loading(translate('loading'));
    let filing = null;
    filing = await getBase64(file);
    authHttp.post(`api/salesforce/lead/${store.getState().id}/attachment/`, {
      Title: `${file.name}_${name}`,
      VersionData: filing.split(',')[1],
      ContentLocation: 'S',
      PathOnClient: file.name,
    })
    .then(async (res) => {
      const data = res.data;
      const mockupList = updatedLists(step, data.content_document_id,
        `${file.name}_${name}`, `${file}`);
      console.log(mockupList, 'uploaded file');
      setListFiles([...mockupList]);
      store.dispatch(setDocsData([...mockupList]));
      localStorage.setItem('docs', JSON.stringify(mockupList));
      toast.success(`${step} ${translate('upload_docs_success')}`, {
        id: toastId,
      });
    })
      .catch(() => {
        toast.error(translate('upload_unsucessful'), {
          id: toastId,
        });
      })
      .finally(() => {
        toast.dismiss();
      });
  };

  const updatedLists = (step, Id, Name, file) => {
    const mockupList = listFiles?.map((item, index) => {
      if (index === step) {
        return {...item, Id, Name, file};
      } else {
        return item;
      }
    });
    return mockupList;
  };

  const dataLayerHandler = () => {
    const existingLoanInfo = JSON.parse(localStorage.loanInfo || '{}');
    const updatedDataLayer = {
      ...existingLoanInfo.dataLayer,
      stap_aanvraag: 'Bedrijfsdocumenten',
    };
    const tagManagerArgs = {
        gtmId: env.GtmId,
        dataLayer: updatedDataLayer,
    };
    const updatedInfo = {
      ...existingLoanInfo,
      dataLayer: updatedDataLayer,
  };
    window.dataLayer = window.dataLayer || [];
    TagManager.initialize(tagManagerArgs);
    localStorage.setItem('loanInfo', JSON.stringify(updatedInfo));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    dataLayerHandler();
    sendCheckBoxes();
  };

  const handleFileChange = (index, body, file) => {
    if (file) {
      const validExtensions = [
        '.pdf',
        '.docx',
        '.pptx',
        '.jpg',
        '.jpeg',
        '.xlsx',
      ];
      const currentExtension = '.' + file.name.split('.').pop();
      if (!validExtensions.includes(currentExtension.toLowerCase())) {
        toast.error(translate('invalid_file_type'));
        return;
      } else {
        setSelectedType(index);
        setIsUpload(true);
        uploadFiles(index, body, file);
        setTimeout(() => {
          setSelectedType(null);
          setIsUpload(false);
        }, 2000);
      }
    }
  };


  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const sendCheckBoxes = () => {
    const toastId = toast.loading(translate('loading'));
    const resultObject = {
      Documents_Attached__c: listFiles ? listFiles?.map((el) =>
        el.Name).join(',') : '',
    };
    authHttp.patch(`api/salesforce/lead/${store.getState().id}/`, {
      ...resultObject,
    })
      .then(() => {
    handleDone(!aanvraag ? 7 : 6);
    toast.success(translate('submitted_details_success'), {
          id: toastId,
        });
      })
      .catch(() => {
        toast.error(translate('details_fail_submission'), {
          id: toastId,
        });
      })
      .finally(() => toast.dismiss());
  };

  const initDelete = (index: number, val: FileProps) => {
    const toastId = toast.loading(translate('deleting'));
    authHttp.delete(`api/salesforce/attachment/${val.Id}/`)
      .then(() => {
        toast.success(translate('deleted'), {
          id: toastId,
        });
        setModalShow(false);
        const mockupList = updatedLists(index, '',
          '', null);
        setListFiles([...mockupList]);
        if (mockupList.length) {
          store.dispatch(setDocsData([...mockupList]));
          localStorage.setItem('docs', JSON.stringify(mockupList));
        }
      })
      .catch(() => {
        toast.error(translate('delete_unsuccessful'), {
          id: toastId,
        });
      })
      .finally(() => setSelectedIndex(null));
  };

  const deleteDoc = () => {
    setModalShow(false);
    setSelectedIndex(deleteData.index);
    initDelete(deleteData.index, deleteData.file);
    setTimeout(() => {
      setSelectedIndex(null);
    }, 3000);
  };

  const handleDeleting = (index: number) => {
    setModalShow(true);
    setSelectedType(index);
    setDeleteData({index, file: listFiles[index]});
  };

  React.useEffect(() => {
    const inValidFiles = listFiles?.filter((el) => el.isRequired && !el.file);
    if (inValidFiles?.length > 0) setValidated(false);
    else setValidated(true);
  }, [listFiles]);

  return (
    <Card.Body className="mobile_form pb-5 pt-3">
      <div className="mobile_header">{translate('company_documents')}</div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={() => deleteDoc()}
      />
      <p className="mt-1 mobile_details">
        {translate('info_app_faster')}
      </p>
      <Form validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="validationCustom01">
          {listFiles?.map((el: FileProps, index: number) => (
              <div key={index}>
                <Form.Label className="my-3">
                  {el.Body}
                  {el.isRequired && <b style={{color: 'red'}}>*</b>}
                </Form.Label>
                <label
                  className={`${
                    selectedType === index && isUpload && 'isUploading'
                  }
                uploadFile`}
                  style={{
                    animationDuration:
                      selectedType === index && isUpload ?
                        '2s' :
                        `${Math.random() * 5}s`,
                  }}
                >
                  <span className="filename">
                    {translate('upload_pdf_word')}
                  </span>
                  <input
                    type="file"
                    id="file-input"
                    accept=".xls,.xlsx,.xltx,.doc,.docx,.dotx,
.ppt,.pptx,.jpg,.jpeg,.png,.svg,.pdf,.eps"
                    onChange={async (
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      handleFileChange(index, el.Body, e.target.files[0]);
                      e.target.value = '';
                    }}
                    className="inputfile form-control"
                    name="file"
                  />
                </label>
                {el.file && (
                  <div
                    className={`${
                      selectedIndex === index &&
                      selectedType === index &&
                      'isDeleting'
                    } file-added`}
                    style={{
                      animationDuration:
                        selectedIndex === index && selectedType === index ?
                          '2s' :
                          `${Math.random() * 5}s`,
                      marginTop: '12px',
                    }}
                  >
                    <div className="m-0 row w-100 align-items-center">
                      <div
                        className="text-center pr-0 col-2
                   cursor-pointer"
                      >
                        <div>
                          <svg
                            width="18"
                            height="21"
                            viewBox="0 0 18 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => handleDeleting(index)}
                          >
                            <path
                              d="M5.27344 0.756958C5.46875 0.327271
                        5.89844 0.053833 6.36719 0.053833H11.0938C11.5625
                         0.053833 11.9922 0.327271 12.1875 0.756958L12.5
                          1.30383H16.25C16.9141 1.30383 17.5 1.88977 17.5
                           2.55383C17.5 3.25696 16.9141 3.80383 16.25
                            3.80383H1.25C0.546875 3.80383 0 3.25696 0
                             2.55383C0 1.88977 0.546875 1.30383 1.25
                              1.30383H5L5.27344 0.756958ZM15.3906
                               18.296C15.3516 19.3116 14.5312 20.0538
                                13.5156 20.0538H3.94531C2.92969 20.0538
                                 2.10938 19.3116 2.07031 18.296L1.21094
                                  5.05383H16.25L15.3906 18.296Z"
                              fill="#5EC369"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="col-10">
                        <div className="file-name">
                          {el.file && `${el.Name.slice(0, 33)}
                           ${el.Name.length > 33 ? '...' : ''}`}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </Form.Group>
        <div className="mt-5">
          <div>
            <button
              disabled={!validated}
              type="submit"
              className="btn
             btn-success w-100 mobile_button"
            >
              {translate('continue_alt')}
            </button>
          </div>
          <div>
            <button
              className="btn btn-outline-warning w-100 mobile_button"
              onClick={() => handleDone(4)}
            >
              {translate('previous_step')}
            </button>
          </div>
          <div>
            <button className="btn btn-primary w-100 mobile_button">
              <svg
                width="43"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.957 17.3789L20.9258 21.7188C20.7969
                22.3633 20.2812 22.793 19.6367 22.793C8.80859 22.75 0
                  13.9414 0 3.11328C0 2.46875 0.386719 1.95312 1.03125
                  1.82422L5.37109 0.792969C5.97266 0.664062 6.61719
                    1.00781 6.875 1.56641L8.89453 6.25C9.10938 6.80859
                    8.98047 7.45312 8.50781 7.79688L6.1875 9.6875C7.64844
                      12.6523 10.0547 15.0586 13.0625 16.5195L14.9531
                      14.1992C15.2969 13.7695 15.9414 13.5977 16.5
                        13.8125L21.1836 15.832C21.7422 16.1328 22.0859
                        16.7773 21.957 17.3789Z"
                  fill="white"
                />
              </svg>
              {translate('call')}
            </button>
          </div>
        </div>
      </Form>
    </Card.Body>
  );
};

export default DocumentsForm;
