/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import {useTranslation} from 'react-i18next';
// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    icon: 'enflag',
  },
  {
    label: 'Dutch',
    value: 'nl',
    icon: 'nlflag',
  },
];

export default function useLocales() {
  const {i18n, t: translate} = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
