import axios from 'axios';
import store, {getToken} from '@/store';
import env from '@/constants/env';

// Make an 'instance' of axios
const authHttp = axios.create({
  // where we make our configurations
  // baseURL: window.location.origin,
  baseURL: env.ApiServerUrl || window.location.origin,
});

authHttp.interceptors.request.use((config) => config,
  (error) => {
    return Promise.reject(error);
  });

// Add configure interceptors && all the other cool stuff
authHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.message);
  },
);

const apiHttp = axios.create({
  // where we make our configurations
  // baseURL: window.location.origin,
  baseURL: env.ApiServerUrl || window.location.origin,
});

apiHttp.interceptors.request.use((config) => {
  const url = config?.url?.split('/') || [];

  if (!['login', 'forgotten'].includes(url[1]))
    config.headers['Authorization'] = `Bearer ${store.getState().token}`;

  return config;
},
(error) => {
  return Promise.reject(error);
});

// Add configure interceptors && all the other cool stuff
apiHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.message);
  },
);

apiHttp.interceptors.response.use(undefined, async (error) => {
  const errorResponse = error.match(/\d+/);
  if (
    errorResponse[0] === 401
  ) {
    await store.dispatch(getToken());
    errorResponse.response.config.__isRetryRequest = true;
    errorResponse.response.config.headers.Authorization =
      `Bearer ${store.getState().token}`;
    return Promise.resolve(axios(errorResponse.response.config));
  }

  return Promise.reject(errorResponse.response);
});

export {authHttp, apiHttp};
