import useLocales from '@/hooks/useLocales';
import React from 'react';

const Login: React.FC = () => {
  const {translate} = useLocales();
  return (
    <div className="login">
      <form>
        <div className="inputs">
          <input type="text" placeholder={translate('username_email')} />
          <input type="password" placeholder={translate('password')} />
          <p className="light">
            <a href="#">{translate('forgot_password')}</a>
          </p>
        </div>
      </form>
      <footer>
        <button>{translate('continue')}</button>
        <p>
          {translate('have_account')} <a href="#">{translate('sign_up')}</a>
        </p>
      </footer>
    </div>
  );
};

export default Login;
