import useLocales from '@/hooks/useLocales';
import React from 'react';
import {Link} from 'react-router-dom';

const NotFound: React.FC = () => {
  const {translate} = useLocales();
  return (
    <section className="page_404" style={{
      height: '83vh', display: 'flex', justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <div>
      <h1>{translate('page_404')}</h1>
      <p>{translate('page_404_sub')}</p>
      <Link className="button" to="/">
        <i className="icon-home"></i> {translate('go_back_home')}
      </Link>
      </div>
    </section>
  );
};

export default NotFound;
