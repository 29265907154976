/* eslint-disable camelcase */
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from '@/views/App';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import {Provider} from 'react-redux';
import {Toaster} from 'react-hot-toast';
import store, {getToken, fetchDataStart, setFinalStep} from '@/store';
import TagManager from 'react-gtm-module';
import env from './constants/env';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const Init: React.FC = () => {
  const loginFetch = async () => {
    store.dispatch(fetchDataStart(true));
    store.dispatch(setFinalStep(false));
    await store.dispatch(getToken());
  };

  React.useEffect(() => {
    const tagManagerArgs = {
      gtmId: env.GtmId,
      dataLayer: {
        'event': 'Krediet aanvraag stap',
        'stap_aanvraag': 'Kredietinformatie start',
        'leenbedrag': 0,
        'looptijd': 0,
      },
    };
      window.dataLayer = window.dataLayer || [];
      TagManager.initialize(tagManagerArgs);
  }, []);

  React.useEffect(() => {
    loginFetch();
  }, []);

  return (
    <React.StrictMode>
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
      >
        <Toaster position="top-right" />
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

createRoot(
  document.getElementById('app'),
).render(
  <Init/>
  ,
);
